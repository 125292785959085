<template>
  <LayoutPage title="Reportes de reversiones">
    <!-- filtros table  -->
    <v-expansion-panels v-model="panel" class="mb-3">
      <v-expansion-panel :key="0">
        <v-expansion-panel-header class="outline_none" disable-icon-rotate>
          <template v-slot:actions>
            <span class="primary--text text-subtitle-1 mt-0">Filtros</span>
            <v-icon size="33" color="success"> search </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <!-- 1 fila de filtros -->
          <v-row class="pt-0 mx-5">
            <v-col cols="6" md="3">
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                dark
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="ml-5"
                    label="Fecha de inicio"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    persistent-hint
                    :value="formatDate(fechaInicio)"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaInicio"
                  locale="es-ar"
                  no-title
                  scrollable
                  :max="new Date().toISOString().substr(0, 10)"
                  @change="handleFechaInicioChange"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" md="3">
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                dark
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="ml-5"
                    label="Fecha de fin"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    persistent-hint
                    :value="formatDate(fechaFin)"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaFin"
                  locale="es-ar"
                  no-title
                  scrollable
                  :min="fechaInicio"
                  :max="new Date().toISOString().substr(0, 10)"
                  @change="handleFechaFinChange"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!--end filtros-->

    <!-- Tabla Principal -->
    <v-data-table
      :headers="headers"
      :items="reversiones.filter((rev) => rev.Fecha)"
      :loading="loading"
      no-data-text="Sin registros para mostrar"
      loading-text="Cargando..."
      hide-default-footer
      @page-count="pageCount = $event"
      :page.sync="page"
      :items-per-page="rowsPerPage"
      :item-class="validarFecha"
    >
      <template v-slot:[`item.Nombre`]="{ item }">
        {{ getNombreArchivo(item.Nombre) + ".xlsx" }}
      </template>
      <template v-slot:[`item.TotalMonto`]="{ item }">
        {{ formatImport(item.TotalMonto) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom color="black">
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              color="green darken-3"
              @click="downloadExcel(item)"
              v-on="on"
              ><v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>
          </template>
          <span>Descargar Excel</span>
        </v-tooltip>
        <v-tooltip bottom> </v-tooltip>

        <!-- descargar pdf -->
        <v-tooltip bottom color="black">
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              color="red darken-3"
              @click="downloadPdf(item)"
              v-on="on"
              ><v-icon>mdi-file-download</v-icon>
            </v-btn>
          </template>
          <span>Descargar PDF</span>
        </v-tooltip>

        <v-tooltip bottom color="black">
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              color="grey darken-3"
              @click="showPreview(item)"
              v-on="on"
              ><v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
          <span>Vista previa</span>
        </v-tooltip>
      </template>

      <template v-slot:footer>
        <v-row class="no-gutters mt-2 mx-2">
          <v-flex xs12>
            <span>Mostrar</span>
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn dark text color="primary" class="ml-2" v-on="on">
                  {{ rowsPerPage }}
                  <v-icon>arrow_drop_down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(number, index) in rowsPerPageArray"
                  :key="index"
                  @click="rowsPerPage = number"
                >
                  <v-list-item-title>{{ number }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-flex>
        </v-row>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination v-model="page" :length="pageCount"></v-pagination>
    </div>

    <div id="pdf-preview"></div>

    <!-- PREVIEW REPORTE -->
    <DetalleReversion v-model="preview" :previewData="previewData" />

    <!-- Modal de Filtro de Fechas -->
    <v-dialog v-model="RestriccionFecha" persistent max-width="350">
      <v-card>
        <v-card-title class="text-h5">
          <v-col>
            <v-row
              ><div class="flex justify-center">
                <v-icon x-large color="red">mdi-close-octagon</v-icon>
              </div></v-row
            >
            <v-row></v-row>
            <v-row>
              <div class="flex justify-center">
                <h1>Rango de Fechas</h1>
              </div>
            </v-row>
          </v-col>
        </v-card-title>
        <v-card-text>No puede ser mayor a 31 dias</v-card-text>
        <v-card-actions>
          <div class="flex justify-center">
            <v-btn
              color="green darken-1"
              class="text-white"
              small
              @click="closeModal"
            >
              Aceptar
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </LayoutPage>
</template>

<script>
import moment from "moment";
import LayoutPage from "../../../components/LayoutPage.vue";
import formatDate from "../../../utils/formatDate";
import formatImport from "../../../utils/formatImport";
import { mapGetters, mapActions } from "vuex";

import ExcelJS from "exceljs";
import saveAs from "file-saver";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import PDFObject from "pdfobject";
// import Papa from "papaparse";
import { dataEncabezadoPDF } from "@/utils/dataEncabezadoPDF";
import DetalleReversion from "../../../components/Reportes/Reversiones/DetalleReversion.vue";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  name: "ReporteReversiones",
  components: { LayoutPage, DetalleReversion },
  data: () => ({
    RestriccionFecha: false,
    panel: 0,
    //Menu Pickers
    menu1: false,
    menu2: false,
    headers: [
      { text: "FECHA", value: "Fecha", sortable: false },
      { text: "NOMBRE", value: "Nombre", sortable: false },
      {
        text: "OPERACIONES",
        value: "CantidadOperaciones",
        sortable: false,
        align: "center",
      },
      { text: "MONTO TOTAL", value: "TotalMonto", sortable: false },
      {
        text: "ACCIONES",
        value: "actions",
        sortable: false,
        align: "center",
      },
    ],

    preview: false,
    item: [],
    previewData: { fecha: "", items: [] },

    rowsPerPageArray: [10, 30, 50],
    rowsPerPage: 10, // cantidad de registros que se muestran en la tabla
    page: 1, // pagina actual de la tabla
    pageCount: 0, // cantidad de paginas

    dataExcelExport: [],

    formatDate,
    formatImport,
  }),

  // si el usuario cambia de cliente, volver a realizar la llamada a la api
  watch: {
    getCurrentClientId() {
      this.getData();
    },
    getCurrentAccountId() {
      this.getData();
    },
  },

  computed: {
    ...mapGetters([
      "getCurrentClientId",
      "getCurrentAccountId",
      "CLIENTE_CUENTA_DATA",
    ]),
    ...mapGetters("reportes", [
      "reversiones",
      "loading",
      "fechaInicio",
      "fechaFin",
    ]),

    fechaInicio: {
      get() {
        return this.$store.state.reportes.fechaInicio; // Obtiene fechaFin desde Vuex
      },
      set(value) {
        this.setFechaInicio(value); // Llama a la acción setFechaFin para actualizar Vuex
      },
    },

    fechaFin: {
      get() {
        return this.$store.state.reportes.fechaFin; // Obtiene fechaFin desde Vuex
      },
      set(value) {
        this.setFechaFin(value); // Llama a la acción setFechaFin para actualizar Vuex
      },
    },

    daysDifference() {
      return moment(this.fechaFin).diff(moment(this.fechaInicio), "days");
    },
  },

  mounted() {
    this.$nextTick(() => this.getReversiones());
  },

  methods: {
    ...mapActions("reportes", [
      "getReversiones",
      "setFechaInicio",
      "setFechaFin",
    ]),

    //Para realizar las busquedas por fecha
    async handleFechaInicioChange(value) {
      await this.setFechaInicio(value);
      this.menu1 = false;
      this.page = 1;
      this.getData();
    },

    async handleFechaFinChange(value) {
      await this.setFechaFin(value);
      this.menu2 = false;
      this.page = 1;
      this.getData();
    },

    //Cerrar Modal de restriccion
    closeModal() {
      this.RestriccionFecha = false;
    },

    // Funcion Get para traer Reversiones
    async getData() {
      if (this.daysDifference > 31) {
        this.RestriccionFecha = true;
        return;
      }
      this.getReversiones();
    },

    //Vista detalle de Reversiones
    showPreview(item) {
      const data = item.Reversiones;
      this.item = item;
      this.preview = true;
      this.previewData = {
        fecha: item.Fecha,
        TotalMonto: item.TotalMonto,
        items: data,
      };
    },

    // Función para descargar Excel
    async downloadExcel(item) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Reversiones");

      // Define el estilo para los encabezados
      const headerStyle = {
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "D3D3D3" }, // Fondo gris claro
        },
        font: {
          bold: true,
        },
        border: {
          top: { style: "thin", color: { argb: "000000" } },
          left: { style: "thin", color: { argb: "000000" } },
          bottom: { style: "thin", color: { argb: "000000" } },
          right: { style: "thin", color: { argb: "000000" } },
        },
      };

      const titleStyle = {
        font: {
          bold: true,
          size: 12, // Tamaño de fuente menor para el título
        },
        alignment: {
          horizontal: "left", // Centrar el texto
        },
      };

      // Agregar título con estilo
      const titleRow = worksheet.addRow([`REPORTE DE REVERSIONES`]);
      titleRow.eachCell({ includeEmpty: true }, (cell) => {
        cell.style = titleStyle;
      });

      worksheet.addRow([`FECHA: ${item.Fecha}`]);

      worksheet.addRow([]); // Fila en blanco

      // Definir encabezados
      let HEADER_TABLE = [
        "ID",
        "REFERENCIA EXTERNA",
        "ESTADO",
        "FECHA TRANSFERENCIA",
        "MONTO",
        "MONTO REVERTIDO",
      ];

      // Agregar encabezados con estilo
      const headerRow = worksheet.addRow(HEADER_TABLE);
      headerRow.eachCell({ includeEmpty: true }, (cell) => {
        cell.style = headerStyle;
      });

      // Agregar datos
      item.Reversiones.forEach((reversion) => {
        const row = [
          reversion.IdPago,
          reversion.ExternalReference,
          reversion.Estado,
          reversion.FechaTransferencia,
          formatImport(reversion.Amount),
          formatImport(reversion.MontoRevertido),
        ];
        worksheet.addRow(row);
      });

      // Ajustar el ancho de las columnas
      worksheet.columns.forEach((column, index) => {
        let maxLength = 0;
        column.eachCell({ includeEmpty: true }, (cell) => {
          const length = cell.value ? cell.value.toString().length : 8;
          if (length > maxLength) {
            maxLength = length;
          }
        });

        // Establecer el ancho mínimo y máximo para las columnas
        const minWidth = 8;
        const maxWidth = 18;

        column.width = Math.min(Math.max(maxLength, minWidth), maxWidth);
      });

      // Generar el archivo y guardarlo
      const buffer = await workbook.xlsx.writeBuffer();
      saveAs(
        new Blob([buffer], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        this.getNombreArchivo(item.Nombre) // Usar la función para obtener el nombre del archivo
      );
    },

    validarFecha(item) {
      if (!item.Fecha) return "hidden";
    },

    //Generador de PDF
    async downloadPdf(item) {
      // Construye las filas de la tabla condicionalmente
      const tableRows = item.Reversiones.map((r) => [
        r.IdPago,
        r.ExternalReference,
        r.Estado,
        r.FechaTransferencia,
        formatImport(r.Amount),
        formatImport(r.MontoRevertido),
      ]);

      // Datos adicionales para el PDF
      const data = {
        fecha: item.Fecha,
        nroReporte: item.NroReporte,
        nombre: item.Nombre,
        totalRevertido: item.TotalReversion,
      };

      // Definir encabezados
      const HEADERS = [
        { text: "ID" },
        { text: "REFERENCIA EXTERNA" },
        { text: "ESTADO" },
        { text: "FECHA TRANSFERENCIA" },
        { text: "MONTO" },
        { text: "MONTO REVERTIDO" },
      ];

      const fontSizeTabla = 8; // Reducir el tamaño de la tabla

      const content = [
        // ENCABEZADO EN UNA SOLA LÍNEA
        {
          columns: [
            {
              text: "REPORTE DE REVERSIONES",
              fontSize: 10,
              bold: true,
              alignment: "left",
            },
            {
              text: `Fecha: ${data.fecha}`,
              fontSize: 9,
              bold: true,
              alignment: "right",
            },
          ],
          margin: [0, 10, 0, 0],
        },

        // TABLA DE REVERSIONES
        {
          layout: "headerLineOnly",
          style: "tabla",
          table: {
            headerRows: 1,
            widths: ["*", "*", "*", "*", "*", "*"],
            // alignment: "center",
            body: [HEADERS, ...tableRows],
          },
        },

        // TOTALES
        {
          text: `Total Revertido: ${formatImport(item.TotalMonto)}`,
          style: "totales",
          alignment: "right",
          margin: [0, 20, 0, 0],
        },
      ];

      // CREANDO PDF
      const docDefinition = {
        header: dataEncabezadoPDF(this.CLIENTE_CUENTA_DATA),
        content,
        images: {
          wee: `${window.location.origin}/assets/img/wee_logo.png`,
        },
        pageMargins: [35, 160, 20, 35],
        styles: {
          tabla: {
            fontSize: fontSizeTabla,
            // alignment: "center",
            margin: [0, 15, 0, 0],
          },
          totales: { fontSize: 9, bold: true, alignment: "right", margin: 5 },
          header: {
            fontSize: 7,
            alignment: "left",
            margin: [0, 5, 0, 0],
          },
        },
      };

      return pdfMake
        .createPdf(docDefinition)
        .download(this.getNombreArchivo(data.nombre));
    },

    getNombreArchivo(nombre) {
      return `${nombre.replaceAll(" ", "-").replaceAll(".", "-")}-REVERSION`;
    },
  },
};
</script>

<style scoded>
.v-tooltip__content {
  width: 120px;
}

.tab-active {
  background-image: linear-gradient(
    10deg,
    hsl(195deg 100% 46%) 0%,
    hsl(191deg 100% 44%) 10%,
    hsl(184deg 100% 40%) 20%,
    hsl(175deg 100% 38%) 30%,
    hsl(162deg 73% 44%) 40%,
    hsl(130deg 44% 58%) 50%,
    hsl(112deg 47% 59%) 60%,
    hsl(97deg 54% 57%) 70%,
    hsl(85deg 60% 54%) 80%,
    hsl(74deg 66% 50%) 90%,
    hsl(65deg 99% 42%) 100%
  );
}
</style>

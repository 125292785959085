<template>
  <div>
    <!-- PREVIEW REPORTE -->
    <v-dialog
      v-model="showDetailModal"
      max-width="45%"
      overlay-opacity="0.6"
      class="white"
      style="z-index: 14000 !important;"
    >
      <v-card class="px-1 rounded-lg">
        <v-card-title class="text-h5 font-weight-bold d-flex align-center">
          Detalle del Pagador
          <v-spacer />
          <v-btn icon @click="showDetailModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-list>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="primary">mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-subtitle-1 font-weight-bold">
                Nombre
              </v-list-item-title>
              <v-list-item-subtitle>{{
                previewDataDetail.data.Nombre
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item>
            <v-list-item-icon>
              <v-icon color="primary">mdi-email</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-subtitle-1 font-weight-bold">
                Email
              </v-list-item-title>
              <v-list-item-subtitle>{{
                previewDataDetail.data.Email
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item>
            <v-list-item-icon>
              <v-icon color="primary">mdi-tag</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-subtitle-1 font-weight-bold">
                Tipo
              </v-list-item-title>
              <v-list-item-subtitle>{{
                previewDataDetail.data.Tipo
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item>
            <v-list-item-icon>
              <v-icon color="primary">mdi-card-account-details</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-subtitle-1 font-weight-bold">
                Número
              </v-list-item-title>
              <v-list-item-subtitle>{{
                previewDataDetail.data.Numero
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import formatImport from "../../../utils/formatImport";

export default {
  name: "ReporteReversiones",
  props: {
    value: Boolean, // v-model pasará el valor aquí
    previewDataDetail: Object,
  },
  data: () => ({
    headerPreview: [
      { text: "Nombre", value: "Nombre" },
      { text: "Numero", value: "Numero" },
      { text: "Email", value: "Email" },
      { text: "Tipo", value: "Tipo" },
    ],
    formatImport,
  }),

  computed: {
    showDetailModal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val); // Esto actualiza `preview` en el padre
      },
    },
  },
  mounted() {},
  methods: {},
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LayoutPage',{attrs:{"title":"Reportes de reversiones"}},[_c('v-expansion-panels',{staticClass:"mb-3",model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',{key:0},[_c('v-expansion-panel-header',{staticClass:"outline_none",attrs:{"disable-icon-rotate":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('span',{staticClass:"primary--text text-subtitle-1 mt-0"},[_vm._v("Filtros")]),_c('v-icon',{attrs:{"size":"33","color":"success"}},[_vm._v(" search ")])]},proxy:true}])}),_c('v-expansion-panel-content',[_c('v-row',{staticClass:"pt-0 mx-5"},[_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"ml-5",attrs:{"label":"Fecha de inicio","readonly":"","persistent-hint":"","value":_vm.formatDate(_vm.fechaInicio)}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"locale":"es-ar","no-title":"","scrollable":"","max":new Date().toISOString().substr(0, 10)},on:{"change":_vm.handleFechaInicioChange},model:{value:(_vm.fechaInicio),callback:function ($$v) {_vm.fechaInicio=$$v},expression:"fechaInicio"}})],1)],1),_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"ml-5",attrs:{"label":"Fecha de fin","readonly":"","persistent-hint":"","value":_vm.formatDate(_vm.fechaFin)}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"locale":"es-ar","no-title":"","scrollable":"","min":_vm.fechaInicio,"max":new Date().toISOString().substr(0, 10)},on:{"change":_vm.handleFechaFinChange},model:{value:(_vm.fechaFin),callback:function ($$v) {_vm.fechaFin=$$v},expression:"fechaFin"}})],1)],1)],1)],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.reversiones.filter(function (rev) { return rev.Fecha; }),"loading":_vm.loading,"no-data-text":"Sin registros para mostrar","loading-text":"Cargando...","hide-default-footer":"","page":_vm.page,"items-per-page":_vm.rowsPerPage,"item-class":_vm.validarFecha},on:{"page-count":function($event){_vm.pageCount = $event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.Nombre",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNombreArchivo(item.Nombre) + ".xlsx")+" ")]}},{key:"item.TotalMonto",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatImport(item.TotalMonto))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"green darken-3"},on:{"click":function($event){return _vm.downloadExcel(item)}}},on),[_c('v-icon',[_vm._v("mdi-microsoft-excel")])],1)]}}],null,true)},[_c('span',[_vm._v("Descargar Excel")])]),_c('v-tooltip',{attrs:{"bottom":""}}),_c('v-tooltip',{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"red darken-3"},on:{"click":function($event){return _vm.downloadPdf(item)}}},on),[_c('v-icon',[_vm._v("mdi-file-download")])],1)]}}],null,true)},[_c('span',[_vm._v("Descargar PDF")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"grey darken-3"},on:{"click":function($event){return _vm.showPreview(item)}}},on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Vista previa")])])]}},{key:"footer",fn:function(){return [_c('v-row',{staticClass:"no-gutters mt-2 mx-2"},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',[_vm._v("Mostrar")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"dark":"","text":"","color":"primary"}},on),[_vm._v(" "+_vm._s(_vm.rowsPerPage)+" "),_c('v-icon',[_vm._v("arrow_drop_down")])],1)]}}])},[_c('v-list',_vm._l((_vm.rowsPerPageArray),function(number,index){return _c('v-list-item',{key:index,on:{"click":function($event){_vm.rowsPerPage = number}}},[_c('v-list-item-title',[_vm._v(_vm._s(number))])],1)}),1)],1)],1)],1)]},proxy:true}],null,true)}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('div',{attrs:{"id":"pdf-preview"}}),_c('DetalleReversion',{attrs:{"previewData":_vm.previewData},model:{value:(_vm.preview),callback:function ($$v) {_vm.preview=$$v},expression:"preview"}}),_c('v-dialog',{attrs:{"persistent":"","max-width":"350"},model:{value:(_vm.RestriccionFecha),callback:function ($$v) {_vm.RestriccionFecha=$$v},expression:"RestriccionFecha"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_c('v-col',[_c('v-row',[_c('div',{staticClass:"flex justify-center"},[_c('v-icon',{attrs:{"x-large":"","color":"red"}},[_vm._v("mdi-close-octagon")])],1)]),_c('v-row'),_c('v-row',[_c('div',{staticClass:"flex justify-center"},[_c('h1',[_vm._v("Rango de Fechas")])])])],1)],1),_c('v-card-text',[_vm._v("No puede ser mayor a 31 dias")]),_c('v-card-actions',[_c('div',{staticClass:"flex justify-center"},[_c('v-btn',{staticClass:"text-white",attrs:{"color":"green darken-1","small":""},on:{"click":_vm.closeModal}},[_vm._v(" Aceptar ")])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
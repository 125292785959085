<template>
  <div>
    <!-- PREVIEW REPORTE -->
    <v-dialog
      v-model="showModal"
      max-width="85%"
      class="white"
      style="z-index: 14000 !important;"
    >
      <v-card>
        <v-card-title class="text-h6 font-weight-bold ">
          REPORTE DE REVERSIONES
          <v-spacer />
          <span class="body-1 grey--text text--darken-3">
            Fecha: {{ previewData.fecha }}
          </span>
        </v-card-title>
        <v-divider class="ma-0"></v-divider>

        <!-- Tabla de vista previa -->
        <v-data-table
          :headers="headerPreview"
          :items="previewData.items"
          hide-default-footer
          disable-pagination
          disable-sort
          no-data-text="Sin registros para mostrar"
          class="pb-5 cursor-pointer"
          @click:row="showDetail"
        >
          <template v-slot:[`item.Amount`]="{ item }">
            <span :class="item.Amount < 0 ? 'text-red-500' : ''">
              {{ formatImport(item.Amount) }}
            </span>
          </template>
          <template v-slot:[`item.MontoRevertido`]="{ item }">
            <span :class="item.MontoRevertido < 0 ? 'text-red-500' : ''">
              {{ formatImport(item.MontoRevertido) }}
            </span>
          </template>
        </v-data-table>
        <v-row justify="end" align="center" no-gutters class="px-3 pt-4 pb-1">
          <v-chip
            :ripple="false"
            color="blue lighten-1"
            class="white--text body-1 "
          >
            Monto Total:
            <span class="font-weight-bold ">
              {{ formatImport(previewData.TotalMonto, false) }}
            </span>
          </v-chip>
        </v-row>
      </v-card>
    </v-dialog>
    <DetallePagoIntento
      v-model="previewDetail"
      :previewDataDetail="previewDataDetail"
    />
  </div>
</template>
<script>
import formatImport from "../../../utils/formatImport";
import DetallePagoIntento from "./DetallePagoIntento.vue";

export default {
  name: "ReporteReversiones",
  components: {
    DetallePagoIntento,
  },
  props: {
    value: Boolean, // v-model pasará el valor aquí
    previewData: Object,
  },
  data: () => ({
    headerPreview: [
      {
        text: "Fecha de Transferencia",
        value: "FechaTransferencia",
        align: "center",
      },
      { text: "ID", value: "IdPago", align: "center" },
      {
        text: "External Reference",
        value: "ExternalReference",
        align: "center",
      },
      { text: "Estado", value: "Estado", align: "center" },
      { text: "Transacción ID", value: "TransaccionId", align: "center" },
      {
        text: "Fecha Pago Intento",
        value: "FechaPagointento",
        align: "center",
        width: "143px",
      },
      { text: "Monto", value: "Amount", align: "center" },
      { text: "Monto Revertido", value: "MontoRevertido", align: "center" },
    ],
    previewDetail: false,
    previewDataDetail: { data: [] },
    formatImport,
  }),
  computed: {
    showModal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val); // Esto actualiza `preview` en el padre
      },
    },
  },
  methods: {
    async showDetail(item) {
      const datos = item.Pago;
      this.previewDetail = true;
      this.previewDataDetail = { data: datos };
    },
  },
};
</script>
